import { FC } from 'react';

const ArrowDown: FC = () => {
  return (
    <>
      <svg
        width="37"
        height="19"
        viewBox="0 0 37 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M33.9761 -4.22206e-07L37 2.82578L20.5197 18.2171C20.2557 18.4652 19.9416 18.6621 19.5958 18.7965C19.2499 18.9308 18.8789 19 18.5043 19C18.1296 19 17.7587 18.9308 17.4128 18.7965C17.0669 18.6621 16.7529 18.4652 16.4888 18.2171L7.06997e-07 2.82578L3.0239 0.00266279L18.5 14.4485L33.9761 -4.22206e-07Z"
          fill="black"
        />
      </svg>
    </>
  );
};

export default ArrowDown;
