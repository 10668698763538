import React, { CSSProperties, FC } from 'react';

interface Props {
  style?: CSSProperties | undefined;
}
const CloudUploadIcon: FC<Props> = ({ style }) => {
  return (
    <>
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={style}
      >
        <path
          d="M53 30.5C52.337 30.5 51.7011 30.7634 51.2322 31.2322C50.7634 31.7011 50.5 32.337 50.5 33V43.5325C50.498 45.3798 49.7633 47.1508 48.4571 48.4571C47.1508 49.7633 45.3798 50.498 43.5325 50.5H12.4675C10.6202 50.498 8.84916 49.7633 7.54293 48.4571C6.2367 47.1508 5.50199 45.3798 5.5 43.5325V33C5.5 32.337 5.23661 31.7011 4.76777 31.2322C4.29893 30.7634 3.66304 30.5 3 30.5C2.33696 30.5 1.70107 30.7634 1.23223 31.2322C0.763392 31.7011 0.5 32.337 0.5 33V43.5325C0.503309 46.7055 1.76523 49.7475 4.00885 51.9911C6.25248 54.2348 9.29454 55.4967 12.4675 55.5H43.5325C46.7055 55.4967 49.7475 54.2348 51.9911 51.9911C54.2348 49.7475 55.4967 46.7055 55.5 43.5325V33C55.5 32.337 55.2366 31.7011 54.7678 31.2322C54.2989 30.7634 53.663 30.5 53 30.5Z"
          fill="#5F0052"
        />
        <path
          d="M14.768 19.767L25.5005 9.03447V40.4995C25.5005 41.1625 25.7639 41.7984 26.2328 42.2672C26.7016 42.7361 27.3375 42.9995 28.0005 42.9995C28.6636 42.9995 29.2995 42.7361 29.7683 42.2672C30.2371 41.7984 30.5005 41.1625 30.5005 40.4995V9.03447L41.233 19.767C41.7045 20.2224 42.336 20.4743 42.9915 20.4687C43.647 20.463 44.2741 20.2 44.7376 19.7365C45.2011 19.273 45.464 18.646 45.4697 17.9905C45.4754 17.335 45.2234 16.7035 44.768 16.232L29.768 1.23197C29.2992 0.763289 28.6634 0.5 28.0005 0.5C27.3376 0.5 26.7018 0.763289 26.233 1.23197L11.233 16.232C10.7776 16.7035 10.5256 17.335 10.5313 17.9905C10.537 18.646 10.8 19.273 11.2635 19.7365C11.727 20.2 12.354 20.463 13.0095 20.4687C13.665 20.4743 14.2965 20.2224 14.768 19.767Z"
          fill="#5F0052"
        />
      </svg>
    </>
  );
};

export default CloudUploadIcon;
